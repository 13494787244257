$heightHeader:52px;
$screen-md-max: 768px;

@mixin xs {
    @media (max-width: #{$screen-md-max}) {
        @content;
    }
 }

.icon {
    cursor: pointer;
    transition: 0.7s;
    display: flex;
}

.icon:hover {
    transform: rotate(90deg) scale(1.1);
    color: rgb(70, 67, 67);
}

:global(.MuiInputLabel-formControl-43) {
    position: relative !important;
}

.contextualTitle {
    text-align: center;
    margin: 0;
    width: 100%;
    padding: 0 10px;
}

.contentBtnAddElement button {
    transition: all 0.15s ease-in-out;
    margin: 10px;
    background-color: #10a544;
    color:white; 
}
.contentBtnAddElement:hover button {
    transform: scale(1.015);
    background-color: rgba(38, 139, 197, 0.85) !important;
}
.contentContextual {
  //margin-top:64px;  
    width: 226px;
    background-color: #ECECEC;
    right: 0;
    position: fixed;
    top: 0;
    height: 93.4vh;
    -webkit-box-shadow: -1px 0px 49px 2px rgba(0,0,0,0.4);
    -moz-box-shadow: -1px 0px 49px 2px rgba(0,0,0,0.4);
    box-shadow: -1px 0px 49px 2px rgba(0,0,0,0.4);
}

.contextualMenuContainer {
    position: relative;
    z-index: 3;
}

.headerContextualMenu {
    background-color:#D3D3D3;
    display: flex;
    align-items: center;
    //justify-content: space-between;
    padding: 0 10px 0 18px ;
    color: #1e4c51;
    cursor: pointer;
    height: $heightHeader;
    //margin-top: 33px;
    .h5 {
        margin: 0;
        padding-left: 18%;
        color:#1e4c51;
    }
    span {
        color:#1e4c51;
    }
}
.headerContextualMenu:hover {
    background: #D3D3D3;
}
.hr {
    border-top: 1px solid #b3b3b3;
    margin: 0;
}
.componentContent{
    height: calc(100vh - #{$heightHeader});
    overflow-x: hidden;
    box-sizing: border-box;
    
}
.componentContentStyle {
    padding: 15px 10px;
}
.noContain{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.layerClick {
    position: fixed;
    z-index: 2;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
}