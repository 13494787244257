.ficheDePosteContainer {
  max-width: 600px;
  margin: 0 auto;

  .buttonContainer {
    display: flex;
    justify-content: flex-end;
    padding: 10px 0;

    button:first-child {
      margin-right: 10px;
    }
  }
}