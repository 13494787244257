$screen-md-max: 768px;
 @mixin xs {
    @media (max-width: #{$screen-md-max}) {
        @content;
    }
 }
 $screen-sm-max: 375px;
 @mixin xxs {
    @media (max-width: #{$screen-sm-max}) {
        @content;
    }
 }
  $screen-lg-max: 1360px;
 @mixin md {
   @media( max-width: #{$screen-lg-max}) 
   {
        @content;
   }
 }

 @mixin lg {
    @media(min-width: #{$screen-lg-max}){
      @content;
    }
}



 
.taskOnly{
    min-width: 150px;
}

.docName {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.keyClass {
  width: 100%;
    @include xxs {
    span {
      display: none;
    }
  }
}


.taskSlider { 
  border-radius: 6px;
  color: rgba(0, 0, 0, 0.87);
  width: 100%;
  border: 0;
  display: flex;
  position: relative;
  font-size: .875rem;
  min-width: 0;
  background: #FFF;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  // margin-top: 0.5%;
  flex-direction: column;
  margin-bottom: 2.2%;
  @include md {
    margin-bottom: 3.6%;
  }
  @include xs {
    margin-bottom: 8%;
  }
  @include xxs {
    margin-bottom: 12%;
  }
  :global(.slick-next) {
    right: -15px;
  }
  :global(.slick-prev) {
    left: -15px;
  }
  :global(.slick-next::before) {
    color:black;
  }
  :global(.slick-prev::before) {
    color:black;
  }
      .sliderContentYES{
        border-radius: 6px;
        flex: 1 1 auto;
        padding: 0.8rem 0.8rem;
        position: relative;
        -webkit-box-flex: 1;
        // margin-bottom: 2%;
        padding-bottom: 0.8rem;

        :global(.slick-list) {
          display: flex;
          width: 100%;
          position: relative;
        }
        :global(.slick-track) {
          transform: translate3d(0px, 0px, 0px) !important;
          }
        :global(.slick-track) {
          display: flex;
          [aria-hidden="true"] {
            display: none;
          }
        }
        :global(.slick-dots) {
          @include xs {
            margin: revert;
          }
        }

        .taskContainer{
          min-width: 100px;
          min-height: 50px;
          @include xxs {
            span {
              min-height: 75px;
            }
          }
          cursor:pointer;
          padding:10px;
          margin:5px;
          background: #e9ecef;
          border-radius: 5px;
          .sizeIcon{
            font-size : 20px;
            margin-right : 7px;
            color : rgb(80, 80, 80);
          }
          .contentDescr{
            display: flex;
            [aria-hidden="true"] {
              display: block !important;
              @include xxs {
                display: none !important;
              }
            }
          }
          .date{
            font-size : 11px;
            text-align : right; 
          }
        }
      }
}

.titleSlider{
  color: #FFF !important;
  padding: 15px;
  margin-top: -20px;
  border-radius: 3px;
  margin-right: 1vw;
  margin-left: 1vw;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(19, 100, 87, 0.4);
 // h5{
  //   @include xs {
  //     font-size: .95em;
  //   }
  // }  
}
.badgeReturned{
 
  .taskContainer {
    background-color: rgba(255, 166, 0, 0.6) !important;
  }
}
.projTermine{
  color: rgb(16, 165, 68) !important;
}
.projEnRetard{
  color: orange !important;
}
.projUrgent{
  color: red !important;
}