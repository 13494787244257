.treeSelectContainer {
    :global {
      .dropdown {
        width: 100% !important;
      }

      .dropdown-trigger {
        display: flex !important;
        justify-content: space-between !important;
        align-items: center !important;
        color: black;
        border-color: #ccc !important;
        border-radius: 5px;
      }

      .tag-list,
      .search {
        width: 100% !important;
      }

      .dropdown-trigger[aria-labelledby] .tag-list .tag-item:last-child {
        display: none !important;
      }

      .dropdown-content {
        position: relative !important;
        max-height: 400px !important;
        overflow-y: auto !important;
      }

      .search {
        padding: 5px 10px !important;
        box-sizing: border-box !important;
      }

      .dropdown-content .node,
      .dropdown-content .node label {
        display: flex !important;
        align-items: center !important;
        margin-bottom: 0 !important;
      }

    }
  }