$screen-sm-min: 576px;
$screen-md-min: 1200px;
$screen-md-max: 1200px;
 @mixin xs {
    @media (max-width: #{$screen-md-max}) {
        @content;
    }
 }
@mixin sm {
    @media (min-width: #{$screen-sm-min}) {
        @content;
    }
 }

@mixin md {
    @media (min-width: #{$screen-md-min}) {
        @content;
    }
 }
 
 $screen-sm-max: 375px;
 @mixin xxs {
    @media (max-width: #{$screen-sm-max}) {
        @content;
    }
 }
// .contentBanettes{
//     display : flex;
//     margin-left : 1%;
//     margin-top : -55px;
//     font-size: 14px;
.numberDossierBanette{
    // @include xs {
    //     height: 30px;
    // }
    color: white;
    border-radius : 3px;
    height : 40px;
    margin-left : 15px;
    min-width : 40px;
    margin-top : -20px;
    .numberBanette{
        // @include xs {
        //     margin-left : 10px;
        //     margin-top : 10px;
        // }
        // margin-left : 23px;
        margin-top : 10px;
        width: 100%;
        text-align: center;
    }
}

.colorStatic{
    background-color : #f44336 !important;
    border : 1px solid #dc3545;        
}

.colorGestion{
    background-color : white;
    
}

.colorCustomUser{
    background-color : #ffa726 !important;
    border : 1px solid #fb8c00;    
}





.itemSearch{
    margin-top: 30px;
    border-radius: 5px;
    border : 1px solid rgba(226,226,226,0.62);
    display: flex;
    box-shadow : 0 1px 4px 0 rgba(0, 0, 0, 0.14);
    padding-left: 3px;
    margin-left : 3px;
    margin-right : 5px;
    height : 45px;        
    // background-color : white;
    width : 100%;
    cursor:pointer;
}

.itemSearch2{ //TODO classe a refaire
    margin-top: 30px;
    border-radius: 5px;
    border : 1px solid rgba(226,226,226,0.62);
    display: flex;
    box-shadow : 0 1px 4px 0 rgba(0, 0, 0, 0.14);
    padding-left: 3px;
    margin-left : 3px;
    margin-right : 5px;
    width : 100%;
    max-width: 300px;
    height : 45px;
    background-color : white;
    cursor:pointer;
}

.textBanette{
    padding: 0.75rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.gestionTextBanette{
    color : green;
}

.banetSelectedCustom{
    border : 1px solid #ffa726 !important;   
    color : #ffa726;
    background-color: white;
    font-weight : bold;
    border-radius: 5px;
}
.banetSelectedStatic, .banetGestion{
  //  border : 1px solid #dc3545 !important;        
    background-color: white;
    font-weight : bold;
    border-radius: 5px;
    font-weight: bold;
    color: #dc3545;
}

.iconsBanet{
    display: flex;
    margin-left: auto;
    margin-top: 10px;
    margin-right: 10px;
    color: black;
    :global(.material-icons){
        display: flex !important;
    }
}

.banetSelectedStatic{
    border : 1px solid #dc3545 !important; 
}

.paramBanette{
    width : 350px;
}
.taskBanettes{
    width: 100%;
    border: 0;
    display: flex;
    position: relative;
    font-size: .875rem;
    min-width: 0;
    margin-top: 10px;
    flex-direction: column;
    margin-bottom: 10px;
    @include md {
      margin-bottom: 20px;
    }
    @include xs {
      margin-bottom: 30px;
    }
    @include xxs {
      margin-bottom: 40px;
    }
    :global(.slick-next) {
      margin-top: 15px;
    }
    :global(.slick-prev) {
      margin-top: 15px;
    }
    :global(.slick-next::before) {
      color:black;
    }
    :global(.slick-prev::before) {
      color:black;
    }
    .sliderContentBanettes{
        flex: 1 1 auto;
        padding-left: 1.938rem;
        padding-right: 1.938rem;
        position: relative;
        -webkit-box-flex: 1;
        padding-bottom: 0%;

        :global(.slick-list) {
            display: flex;
            width: 100%;
            position: relative;
        }
        :global(.slick-slide){
            margin-right: 15px;
        }
        :global(.slick-track) {
            transform: translate3d(0px, 0px, 0px) !important;
        }
        :global(.slick-track) {
            display: flex;
            [aria-hidden="true"] {
                display: none;
            }
        }
        .taskContainer{
            min-width: 125px;
            min-height: 50px;
            @include xxs {
                span {
                min-height: 75px;
            }
        }
        cursor:pointer;
            padding:10px;
            margin:5px;
            background: #e9ecef;
            border-radius: 5px;
            .sizeIcon{
                font-size : 12px;
                margin-right : 7px;
                margin-top : 3px;
                color : grey;
            }
            .contentDescr{
                display: flex;
                [aria-hidden="true"] {
                    display: block !important;
                    @include xxs {
                        display: none !important;
                    }
                }
            }
            .date{
                font-size : 11px;
                text-align : right; 
            }
        }
    }
}