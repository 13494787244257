$screen-md-max: 768px;
$screen-sm-max: 576px;

@mixin xs {
  @media (max-width: #{$screen-md-max}) {
    @content;
  }
}

@mixin xxs {
  @media (max-width: #{$screen-sm-max}) {
    @content;
  }
}

// :global(div[class*='MuiCardContent-root']) {
//   padding: 5px !important;
// }


.iconCards {
  cursor: pointer;
  padding: 0;
  width: 100%;
  height: 70%;

  svg {
    width: 100%;
    height: 100%;
  }
}

//  // Formulaire
.pageContainer {
  color: rgba(0, 0, 0, 0.87);
  width: 100%;
  border: 0;
  display: flow-root;
  position: relative;
  font-size: .875rem;
  min-width: 0;
  background: #FFF;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  margin-top: 0;
  border-radius: 6px;
  flex-direction: column;
  margin-bottom: 1%;

  // h5{
  //   @include xs {
  //     font-size: .95em;
  //   }
  // }
  :global(.MuiTabs-flexContainer) {
    @include xs {
      display: grid;
    }
  }
}


:global(div[class*='MuiCard-root']) {
  width: 100%;
  height: 90%;
  background: #e9ecef;
}

// :global(div[class*='MuiGrid-root MuiGrid-container']){
//   overflow-y: scroll;
// }
.gridContainer {
  overflow-y: scroll;
  height: 30vh;
}

.cardsContainer {
  padding-left: 5px;
  display: flex;
  height: 104px;
}

.documentContainer {
  border-radius: 6px;
  padding: 1%;
  min-height: calc(100vh - 115px);
  height: auto;
  border-bottom-right-radius: 0;

  .modeSharepointContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center
  }
}



.groupInput {
  margin-bottom: 20px !important;
  width: 100%;
}

$formBackground: #fafafa;

.formStyle {
  background: $formBackground;

  :global(.MuiOutlinedInput-input) {
    padding: 8px 14px !important;
  }

  .labelFix {
    padding: 0 5px !important;
    background: $formBackground !important;
    display: flex;
  }
}


.expansionPanelDetailMUi {
  display: inline !important;
}

.contentBtn {
  margin-left: 15px;
}

.backgroundTableActif {
  background-color: rgba(58, 146, 196, 0.3) !important;
  border: 1px solid rgba(58, 146, 196, 0.3) !important;
}

.listDz {
  margin-bottom: 20px;

  :global(.dropzone) {
    min-height: 115px !important;
  }

  :global(.dz-message) {
    margin: 0;
  }
}

.contentBouttonSharepoint button:first-child {
  margin-right: 15px;
}

// contentComponent : margin a retirer left 280px right 55px
// .contentForm_1Tjsg {
//   width: 40%;
//   height: 100%;
//   margin-left: 60%;
// }
// .contentForm_1Tjsg {
//   width: 70%;
//   height: 100%;
//   margin-left: 50%;
//   padding-right: 10%;
// }
// document Container : width : 60% float : left
// pageContainer : width 100% display : flex a retirer
.contentForm {
  border-radius: 6px;
  padding: 1%;
  padding-left: 0;
  border-bottom-left-radius: 0;
  display: flex;
  flex-direction: column;
}


.contentTabs {
  @include xs {
    background: transparent !important;
    margin: 0 auto;
  }
}


.banettes {
  width: 72px;
  // margin-top: 10px;
  border: 1px solid black;
  top: 0px;
  margin-left: 20px;
  padding: 5px;
}

.contentAlert {
  margin-bottom: 10px !important;
}

.titleModelDocument {
  .brTitle {
    @include xs {
      display: block;
    }
  }

  h5 {
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }

  color: #FFF !important;
  padding: 15px;
  padding-bottom: 5px;
  margin-top: -20px;
  display : flex;
  border-radius: 3px;
  margin-right: 1vw;
  margin-left: 1vw;

  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .14),
  0 7px 10px -5px rgba(19, 100, 87, 0.4);

  br {
    display: none;
  }

  @include xxs {
    br {
      display: block;
    }
  }

  h5 {

    //  font-size : 17px; 
    .partDateFinSouhaitee {
      white-space: nowrap;
    }

    .iconStyles {
      margin-left: 5px;

      &:hover {
        cursor: pointer;
        color: rgb(202, 255, 202) !important;
        font-weight: bold;
      }
    }

    .btnReturnShareppoint {
      margin-left: 15px;
    }
  }

  .documentImage {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .ficheDocContainer {
    position: fixed;
    top: 0;
    right: 0;
    background: #ebebeb;
    width: 350px;
    height: 100vh;
    z-index: 1120;
    transition: 0.5s ease-in-out right;

    .headerFicheDoc {
      padding: 20px 10px;
      border-bottom: 1px solid rgb(8, 8, 8);
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      transition: 0.1s ease-in-out background;

      &:hover {
        background: #e1e1e1;
      }
    }
  }

  .listDzGlobal {
    position: relative;

    :global(.dropzone.dz-drag-hover) {
      z-index: 9999 !important;
      background-color: rgba(0, 188, 212, 0.1) !important;
      border: 2px dotted rgb(0, 188, 212) !important;
      overflow-x: hidden !important;
    }

    :global(.filepicker-file-icon) {
      display: none;
    }

    :global(.filepicker) {
      border: 0 !important;
      position: absolute !important;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0) !important;
      overflow-x: hidden !important;
      // z-index : 200;
    }

    :global(.dz-message) {
      display: none;
    }
  }

  :global(.MuiExpansionPanelSummary-root) {
    height: 30px;
  }

  $color: myColor;

  .onglet {
    :global(.Mui-selected) {
      background: $color;
      color: blue;
    }

  }

  //A MODIIIIIIIIIIIIIIF
  :global(.selected) {
    background-color: rgb(202, 255, 202);
  }

  .titleCircuitDOrientation {
    margin-top: 20px;
    margin-bottom: 14px;
  }

  .rainbow {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: -40px;
    transition: 0.5s ease;
    opacity: 0;
    transform: rotate(0);

    &:before {
      content: "🌈";
      left: 0;
    }
  }

  &:hover .rainbow {
    opacity: 1;
    animation: rotate-nonstop 0.2s infinite;
  }

  .banettes {
    width: 25px;
    border: 2px solid black;
  }

  // .partDateFinSouhaitee :hover{
  //     color : green !important;
  //    // background-color : red;
  // }

  /* .listDz{
  :global(.dropzone.dz-drag-hover){
      position: absolute;
      width: 100%;
      z-index: 999 !important;
      background-color: rgba(0, 188, 212, 0.1) !important;
      border: 2px dotted rgb(0, 188, 212) !important;
      overflow-x :hidden !important;       
  }
  
  :global(.filepicker-file-icon){
      display: none;
  }

  :global(.filepicker) {
      border: 0 !important;
      position: absolute !important;
      width: 85%;
      height: 85%;
      background-color: rgba(255,255,255,0) !important;
      overflow-x :hidden !important;
      z-index : 400;
  }

  :global(.dz-message) {
      display: none;
  }
} */

  // :global(.slick-prev) {
  //   left: 3% !important;
  //   z-index: 9999 !important;
  //  // background-color : rgba(58,146,196,0.3) !important;
  // }
  // :global(.slick-next) {
  //   right: 75px !important;
  //   z-index: 9999 !important;
  //  // background-color : rgba(58,146,196,0.3) !important;
  // }

  // :global(.slick-list) {
  //   padding-left: 16%;
  //   background-color: rgba(58,146,196,0.3);
  //   margin-top: 20px;
  //   margin-bottom: 25px;
  // }

  // :global(.slick-slide) {
  //     width: 100% !important;
  // } 
}

.circuitPanel{

  .titleElement{
    font-size: 1.5em;
    color: #222;
    margin-bottom: 0.5em;
  }
  // .expansionPanelDetailMUi{

  // }
}
.circuitSplit{
  border-top: 1px solid #CCC;
}