$screen-sm-max: 1024px;
$screen-sm-min: 576px;

@mixin xs {
    @media (max-width: #{$screen-sm-max}) {
        @content;
    }
 }
 

.titleDocAssociated {
  text-decoration: underline;
  font-size: 16px;
}


// .select > div {
//   margin-bottom: 12px;
// }

.encadrUploadAZone {
  // width : 85%;
  // height : 100px;
  width: 100%;
  height: 100%;
  margin: none;
  display: flex;
  form {
    display: flex;
    @include xs {
        display: block;
    }
  }
}

// .select {
//   .btnAssocie {
//     min-height: 52px !important;
//     min-width: 52px !important;
//     height: 52px;
//     width: 52px;
//     border-radius: 100px;
//     position: relative;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     border: 4px solid #ffffff;
//     overflow: hidden;
//     background-image: linear-gradient(to bottom, #ffffff 50%, #10a544 50%);
//     background-size: 100% 199%;
//     transition: all 1s;
//     color: #10a544;
//     border-color: #10a544;
//     border-width: thin;
//     // display : flex;
//     &:hover {
//       background-position: 0 -100%;

//       color: #ffffff;
//     }
//   }
// }

.btnAssocie {
  margin-right: 5px !important;
  @include xs {
  margin-right: auto !important;
  margin-left: auto !important;
  bottom: 5px !important;
  }
  width: 20vh;
}

.contentTextfield {
  width: 550px;
}

.txtf {
  width: 200px;
}
.uploadFileName {
      display: flex;
      margin-top : 7px;
      cursor : pointer;
  p {
    margin-left: 5px;
    margin-right: 5px;
    display: flex;
    padding: 0;
    margin-bottom: 0;
  }
}
.uploadFile {
  height: 32px;
  width: 32px;
  border-radius: 100px;
  position: relative; 
  display: flex;
  cursor : pointer;
  justify-content: center;
  align-items: center;
  border: 4px solid #ffffff;
  overflow: hidden;
  background-image: linear-gradient(to bottom, #10a544 50%, #ffffff 50%);
  background-size: 100% 199%;
 // transition: all 1s;
  color: #ffffff;
  border-color: #10a544;
  border-width: thin;
  // display : flex;
  &:hover {
    background-position: 0 -100%;
    color: #10a544;
    border-color: #10a544;
    border-width: thin;
  }

  input {
    height: 50px;
    width: 50px;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }
}

.listing {
  display: flex;
  align-items: center;
}
// .listing > div:nth-child(1) {
//   width: 350px;
// }
.docPrincipal {
  padding-bottom: 7px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.circuitPanel{

  .titleContainer{
    justify-content: space-between;
    .titleElement{
      font-size: 1.5em;
      color: #222;
    }
    
  }
  .addButton{
    border: none;
  }
  .newFile{
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .filesContainer {
      .file {
        margin-top: 5px;
        display: flex;
        align-items: center;
      }
    }


    .fileButtons{
      display: flex;
      align-items: center;
    }
    .fileName{
      font-size: 1.2em;
    }
    .btnUpload{
      margin-left: 10px;
      color: white;
      font-size: 15px;
      padding: 5px;
      height: 25px;
      border-radius: 5px;
      text-transform: initial !important;
    }
  }
  .errorFile{
    color: darkred;
  }
  .dPrincipalTitle{
    margin-bottom: 5px;
  }
  .dSecondaireTitle{
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .listing{
    margin-left: 5px;
    margin-bottom: 5px;
    font-weight: bold;
    text-decoration: underline;
  }
  .noDocText{
    margin-left: 5px;
    font-style: italic;
  }
  .extentions{
    margin-right: 5px;
  }
  .trashcan{
    font-size: 20px;
  }
  .downloadMain,
  .downloadAsso {
    text-decoration: none;
    color: #000;
  }
}
.circuitSplit{
  border-top: 1px solid #CCC;
}