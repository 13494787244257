.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    .modalContent {
        background: white;
        padding: 10px;
        max-height: 80vh;
        max-width: 60vw;
        // text-align: center;
        overflow-x: hidden;
        overflow-y: auto;
        border-radius: 10px;
        min-width: 25% ;
        .modalButton {
            width: fit-content;
            margin: 0 auto;
            .button {
                margin: 5px;
            }
        }
    }
}