.quicklauncher{  
    background-color: black;
    min-width: 40px;
    //min-height: 93.4vh;
    height: 100vh;
    border-top: 1px solid black;
    z-index: 9999999999;
    .param{
        background-color: black;
        height: 52px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        transition: all 0.2s ease-in-out;
    }
    .param span {
        transition: all 0.2s ease-in-out;
    }
    .param:hover span,
    .actif span {
        transform: scale(1.15);
    }
}


// .parameterSelected, .EISelected{
//     background-color : #3a92c4;
// }

// .parameter, .EI{
//     background-color : transparent;
// }