.datePicker{
    width: 100%;
}
.space{
    height: 20px;
}
.btnCancel{
    left: 15px;;
}
.spinner{
    margin-top: 40px;
}
.hiddenInput{
    opacity: 0;
    padding: 0;
    margin: 0;
    border: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    z-index: -1 !important;
}