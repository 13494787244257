$paddingWidth:24px;
$paddingBottom:10px;

$screen-sm-max: 700px;

$screen-xs-max: 400px;

 @mixin sm {
    @media (max-width: #{$screen-sm-max}) {
        @content;
    }
 }

 @mixin xs {
    @media (max-width: #{$screen-xs-max}) {
        @content;
    }
 }

 
 table {
    @include sm {
    table-layout: inherit !important;
    }
}
.bootstrapWf{
    table, th, td {
        padding-left: 0;
        // text-overflow: initial !important;
        .idTable {
            display: none !important;
        }
    td{
        padding-right: 0 !important;
    }
}
}
.contentMyTasks {
/* Force table to not be like tables anymore */
    th{
        span{
            display: contents;
        }
    }
    table, thead, tbody, td, th, tr { 
        width: 100%;
        cursor: pointer; 
        @include xs {
            table {
                width: 100%;
            }
        }
        @include sm {
            table, thead, tbody, th, td, tr { 
                display: block;
            }
            table {
                width: 75%;
                margin-right: auto;
                margin-left: auto;
            }
            /* Hide table headers (but not display: none;, for accessibility) */
            thead tr { 
                position: absolute;
                top: -9999px;
                left: -9999px;
                
            }
            
            tr { border: 1px solid #ccc; }

            td, th { 
                /* Behave  like a "row" */
                border: none;
                border-bottom: 1px solid #eee; 
                position: relative;
                padding-left: 45% !important;
                text-overflow: ellipsis !important;
                width: 100%;
            }
            
            td:before { 
                /* Now like a table header */
                position: absolute;
                /* Top/left values mimic padding */
                top: 6px;
                left: 6px;
                padding-right: 10px; 
                text-overflow: ellipsis !important;
            }

            td:nth-of-type(1):before { content: ""; }
            td:nth-of-type(2):before { content: "Dossier"; }
            td:nth-of-type(3):before { content: "Etat de la t""â""che"; }
            td:nth-of-type(4):before { content: "Nom de la t""â""che"; }
            td:nth-of-type(5):before { content: "Circuit"; }
            td:nth-of-type(6):before { content: "Date de fin estimé""e"; }
            td:nth-of-type(7):before { content: 'Date de fin ré''elle';}
        }
    }
}
.inputContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: $paddingBottom;
}

.inputWorkflow{
    width: 40% !important;
    margin: 0 !important;
}

.datepickerWorkflow{
    width: 25%;
    // margin-left : 2% !important;
}

.btnContainer{
    display: flex;
    justify-content: flex-end;
}

.contentProject{
    padding: 0 $paddingWidth $paddingBottom $paddingWidth;
    border-bottom: 1px solid black;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.displayingProjectName{
    display: flex;
    padding: $paddingBottom $paddingWidth $paddingBottom $paddingWidth;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // border-bottom: 2px solid #3a92c4;
    // background: #3a92c4;
    h3{
        color: white;
        margin:0;
    }
}

.contentAddResponsibleTasl{
    margin-left: 3%;
    margin-top: 1%;
}

.stepDialog{
    display: flex;
}

.dialogTitle {
    padding: $paddingWidth $paddingWidth !important;
    h2 {
    font-size: 18px;
    }
}

.displayStep{
    border-bottom: 1px solid black;
   // margin-top : 10px;
   padding: 5px;
   // font-size: 20px;
   margin-bottom: $paddingBottom;
   z-index: 3;
   position: relative;
}

/* .contentStep:first-child {
    border-top: 0;
} */

.contentStep{
    padding: $paddingBottom $paddingWidth $paddingBottom $paddingWidth;
    // margin: 10px 0;
    border-top: 1px solid white;
    background: #dedede;
    position: relative;
}

// react-select

.editTextField{
    display: flex;
    align-items: center;
} 
/* .editTextField{
    width: 37%;
    z-index: 999999999;
    margin-left: 5%;
} */
.labelReactSelect {
    transform: scale(0.75);
}
/* .editTextField .reactSelect {
    margin-top: 0 !important;
    margin-bottom: 15px !important;
}
 */
.reactSelect{
    width: 100%;
    z-index: 999999999 !important;
}

.reactSelect > div {
    background-color: transparent !important;
    padding:0 0 !important;
    margin-top: 0 !important;
}
.reactSelect > div ~ div {
    background-color: white !important;
    height: inherit;
    margin-top: 5px !important;
    z-index: 999999999999 !important;
}


.reactSelectWF {
    width: 40%;
}

.containModels {
    display: flex;
    justify-content: space-around;
}
/* .selectReact{
    width: 100%;
    z-index: 999999999 !important;
} */

.contentButtonStartWF{
    padding: 0 0 $paddingBottom $paddingBottom;
}

.contentButton{
    padding: 0 0 0 $paddingBottom;
}

.iconActionContainer {
   display: flex;
   justify-content: flex-end;

   span {
       padding: 5px;
       width: auto !important;
       height: auto !important;
       cursor: pointer;
       transition: all 0.2s linear;

       &:hover{
           transform: scale(1.2);
       }
   }
}

.contentBtnConfirmPrj{
    float: right;
    padding: $paddingBottom $paddingWidth $paddingBottom $paddingWidth;
}

.addStep{
    display: flex;
    align-items: center;
}

.addStepContent{
    padding-left: $paddingWidth;
    cursor: pointer;
}

.displayProject{
    font-size : 20px;
    padding: $paddingBottom;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(58,146,196);
    .stateContainer{
        display: flex;
        align-items: center;
    }
}
.contentTask{
    display: flex;
}

.contentBtnControlPublish{
    padding : $paddingWidth 0 0 $paddingWidth;
}

.editIcon {
    padding: $paddingBottom 0 $paddingBottom $paddingBottom;
    cursor: pointer;
}

.modalHeader{
    padding: $paddingBottom $paddingWidth $paddingBottom $paddingWidth;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.iconClosed {
    cursor: pointer;
    margin-left: $paddingWidth;
    transition: all 0.3s ease-in-out;
}

.iconClosed:hover {
    transform: scale(1.2);
}

.modalContent {
    padding: $paddingBottom $paddingWidth $paddingBottom $paddingWidth;
}

.contentButtonAddStep {
    position: absolute;
    bottom: -12px;
    z-index: 2;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: -24px;
}

.contentButtonAddStepFirst {
    position: absolute;
    top: -14px;
    z-index: 2;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: -24px;
}

.addStepButton {
    cursor: pointer;
    background: white;
    padding: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    border-radius: 50%;
    transition: all 0.2s linear;
    &:hover {
        transform: scale(1.4);
    }
    span{
        background-color: #FFF !important;
        font-size: 20px !important;
    }
}

// .colorStylesColumnBasic{
//     color : red;
// }
.colorStylesColumn{
    background-color : red !important;
}

.contentRenderStep{
    border-bottom: 1px solid black;
    cursor: pointer;
}

.contentActions{
    cursor: pointer;
    .iconContent{
       // padding-left: 30%; 
        color: #1e4c51;
        font-size: 18px;
        padding: 10px 15px;
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .icon {
            margin-right:15px;
        }
        &:hover {
            background-color: #DAD8D8;
        }
    }

    .iconContentDisabled {
        cursor: default;

        &:hover {
            background-color: transparent;
        }
    }
}

.iconEtapeOrientation{
    color: #10a544;
    font-size: 18px;
    span{            
        padding-right: 20%;
        padding-top : 3px;
    }
    &:hover {
        background-color:#FFF !important;
        ;
    }
}

.contentActionsCustom{
    cursor: pointer;
}

.custom{
    border : 2px solid black !important;
}

.bootstrapWf{
    font-size : 12px;
    thead{
        line-height: 0 !important;
    }
}

.iconContainer{
    div{
        span{
            font-size : 14px;
        }
    }    
}

.addrole{
    cursor: pointer;
}

.styleLine{
    background-color: #10a544;
    color: #ffffff;

    line-height : 0px !important;
} 

.iconCheckDone, .iconCheckRefuse{
    margin-top: -8px !important;
}

.iconDelegation{
    margin-top: -8px !important;
}

// :global(.react-bs-table){
//     line-height: 0px !important;
// }

// :global(.react-bs-container-body){
//     line-height: 0px !important;
// }

.iconEdit :global(.material-icons){
    line-height : 0px !important;
    display: inline;
    font-size: 16px;
}

.contentFirst{
    color: rgba(0, 0, 0, 0.87);
    width: 100%;
    border: 0;
    display: flex;
    position: relative;
    font-size: .875rem;
    min-width: 0;
    background: #FFF;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
    margin-top: 50px;
    border-radius: 6px;
    flex-direction: column;
    margin-bottom: 6%;
}
.contentCheckbox{
    display: flex;
    color: #FFF !important;
    padding: 15px;
    margin-top: -20px;
    border-radius: 3px;
    margin-right: 1vw;
    padding-bottom: 6px;
    margin-left: 1vw;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(19, 100, 87, 0.4);
    h5 {
        margin-right: 30px;
    }
    label {
        margin-top: -9px;
    }
    @include sm {
        display: block;
    }
}

:global(.MuiSnackbar-root){
    @include sm {
        // left: 16vw !important;
        // width: 70%;
        // right: 0 !important;
        width: 70%;
        left: 50% !important;
        right: auto !important;
    }
    @include sm {
        left: 16vw !important;
        width: 50%;
        right: 0 !important;
    }
}
:global(.MuiSnackbarContent-action){
    @include sm {
        margin-right: 0;
        padding-left: 0;
        span {
        margin-top: -20px;
        right: 0 !important;
    }
    }
}
.contentMyTasks{
    flex: 1 1 auto;
    padding: 0.9375rem 20px;
    position: relative;
    -webkit-box-flex: 1;
    margin-bottom: 2%;
    padding-bottom: 1%;
}

.iconCheckRefuse{
    color : red !important;
    font-weight: bold !important;
}

.iconEtapeOrientation{
    margin: 0px;
}

.textfield{
    padding-bottom: 8%;
    position: relative;
}

.checkboxFiltre{
    @include sm {
        display: grid !important;
        margin-left: 0 !important;
        margin-top: -10px !important;
        svg{
            margin-top: 39px;
            position: absolute;
        }
    }
}
.checkDiv {
    @include sm {
        display: grid;
    }
}

.stylesEtapeOrientation2{
    margin-left : -18% !important;
}   

.stylesDelegationElement{
    margin-bottom : 18px;
}

.stylesDelegationTextfield{
    margin-bottom : 25px !important;
}

.hideText{
    display: none !important;
}

.btnDelegation{
    margin-bottom : 15px !important;
}

.overflowClass{
    :hover{
        background-color : red;
    }
}
hr {
    border-top: 1px solid #707070;
}
.buttonReturn {
    margin-left: 2%;
    margin-bottom: 2%;
}
.titleHistory {
    color: #FFF !important;
    padding: 15px;
    margin-top: -20px;
    margin-bottom: 20px;
    border-radius: 3px;
    margin-right: 1vw;
    margin-left: 1vw;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(19, 100, 87, 0.4);
}
.mainContent{
    color: rgba(0, 0, 0, 0.87);
    width: 100%;
    border: 0;
    display: flex;
    position: relative;
    font-size: .875rem;
    min-width: 0;
    background: #FFF;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
    margin-top: 50px;
    border-radius: 6px;
    flex-direction: column;
    margin-bottom: 2%;
    // .react-bs-table table td, .react-bs-table table th {
      
    // }
    

    table, thead, tbody, td, th, tr { 
        .react-bs-table table td, .react-bs-table table th, .react-bs-table table tr {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        // width: auto;
        @include xs {
            table {
                width: 100%;
            }
        }
        @include sm {
            .tableHeader {
                background-color: red;
            }
            table, thead, tbody, th, td, tr { 
                display: block; 
            }
            table {
                width: 75%;
                margin-right: auto;
                margin-left: auto;
            }
            /* Hide table headers (but not display: none;, for accessibility) */
            thead tr { 
                position: absolute;
                top: -9999px;
                left: -9999px;
    
            }
            
    //         tr { 
    //             border: 1px solid #ccc;
    //             white-space: nowrap;
    // text-overflow: ellipsis;
    //          }
            
            
            td, th { 
    //             white-space: nowrap;
    // text-overflow: ellipsis;
                /* Behave  like a "row" */
                border: none;
                border-bottom: 1px solid #eee; 
                position: relative;
                padding-left: 45% !important;
                width: 100%;
            }
            td:before { 
                // white-space: nowrap;
                // text-overflow: ellipsis;
                /* Now like a table header */
                position: absolute;
                /* Top/left values mimic padding */
                top: 6px;
                left: 6px;
                padding-right: 10px; 
            }
            td:nth-of-type(1):before { content: ""; }
            td:nth-of-type(2):before { content: "Date"; }
            td:nth-of-type(3):before { content: "Acteur"; }
            td:nth-of-type(4):before { content: "Désignation de l'évènement"}
            }
        }
}