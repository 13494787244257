.iconModule {
    width: 45px;
}


.textFilArianne{
    font-size: 16px;
    color: #ddd;
    padding-right: 20px;    
    padding-left: 20px;
    float: left;
    cursor: pointer;
   
}

.textFilArianneVisited{
    font-size: 16px;
    color: white;
    padding-right: 20px;    
    padding-left: 20px;
    float: left;
    font-weight: bold;
}

.toolbarStyle{
    background-color: #10a544 !important;
    padding : 0px !important;
}

.avatar{
    width: 40px; 
}

.text{
    color: white;
    display : flex;

}

.textAvatar{
   color: white;
   font-weight : bold;
   margin-top: 10px;
   margin-left : -10px;
   margin-right: 20px;
}

.logoGala{    
    background-color: #10a544;
    font-family : 'Segoe UI';
    color : white;
    font-size: 30px;
    padding-left:5%;
    width : 280px;    
}

.groupFilAriane{
    display: flex;
}

.groupAvatar{
    display: flex;
    //align-items: center !important;
    margin-right : 10px;   
}

.avatar{
    display: flex;
    justify-content: flex-end;
}

.nameUser{
    display : flex;
    justify-content : space-between;
    flex-direction: column;
}

.logoAvatar{
    display: flex;
    justify-content: flex-end;
}

.userConnectedDisplaying{
    display : flex;
    cursor: pointer;
}

.dropdownUser{
    background-color: #fff;
    z-index: 9999;
    width: 360px;
    position : absolute;
    margin-left: -212px;
    margin-top:6px;
    border:1px groove rgba(0,0,0,0.1);
    box-shadow:5px 5px 5px 0px #cfcfcf;
    height: 150px;    
    div{
        display: flex;
    }
    .nameDropdown{
        margin-top: 20px;
        margin-left: 10%;
    }
}
.dropdownUser::before{
    content:""; 
    border-left:13px solid transparent;
    border-right:13px solid transparent;
    border-bottom: 20px solid #fff;
    position: absolute;
    margin-top:-15px;
    margin-left:318px;
    }

.btn{
    width: 200px;       
}

.btnGroup{
    position: relative;
    margin-top: 20px;
}
 
.btnAccount{
    padding-left: 7%;
    padding-right: 5%;
    //margin-right: 10px;
}

.btnLogout{
    z-index: 999;
    position: relative;
}

.lastnameStyle{
    margin-left: 6px;
    margin-top:5px;
}
.firstnameDisplay{
    margin-top:5px;
}
.lastnameDropdown{
    margin-left: 6px;
}

.mainContent{
    color: rgba(0, 0, 0, 0.87);
    width: 100%;
    border: 0;
    display: flex;
    position: relative;
    font-size: .875rem;
    min-width: 0;
    background: #FFF;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
    margin-top: 50px;
    border-radius: 6px;
    flex-direction: column;
    margin-bottom: 2%;
    .listParams {
        margin: 0 0 20px 20px;
    }
    .button {
        margin: 0 0 20px 20px;
    }
}
.firstnameDropdown, .lastnameDropdown{
    font-weight: bold;
    font-size: 18px;
    margin-top: 10px;
}

.userConnectedDisplaying{
    font-weight: bold;
    //margin-top : 14px;
    font-size: 18px;
    color : white;
}

.titleGala{
    cursor: pointer;
}

.titleParam{
    color: #FFF !important;
    padding: 15px;
    margin-top: -20px;
    margin-bottom: 20px;
    border-radius: 3px;
    margin-right: 1vw;
    margin-left: 1vw;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(19, 100, 87, 0.4);
}