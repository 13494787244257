@import "../../src/components/Menus/styles/quicklauncher_styles.module.scss";
@import "./animation.scss";
$screen-md-max: 768px;
$screen-sm-min: 576px;

@mixin xs {
   @media (max-width: #{$screen-sm-min}) {
       @content;
   }
}

@mixin md {
    @media (max-width: #{$screen-md-max}) {
        @content;
    }
 }

:global(.primaryColor) {
    background-color: #10a544 !important;
}

// Menu contextual (utile dans tous les composant, donc je le mets dans le global)
.titleContextual{
    margin: 0 0 20px 0 !important;
}

.field {
    margin-bottom: 20px !important;
    margin-top: 0 !important;
    width: 100%;
}
.fieldSelectEditUser{
  z-index: 2;
}

.btnCancel {
    margin-left: 10px !important;
}
///////////////////////////////////

.content{ //ok
    background-color: #eee;
    display: flex;
    min-height: 100vh;
    height: auto;
}


.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    .modalContent {
        background: white;
        padding: 10px;
        max-height: 80vh;
        max-width: 60vw;
        // text-align: center;
        overflow-x: hidden;
        overflow-y: auto;
        border-radius: 10px;
        min-width: 25% ;
        .modalButton {
            width: fit-content;
            margin: 0 auto;
            .button {
                margin: 5px;
            }
        }
    }
}
.modalFormModel {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  .modalFormModelContent {
      background: white;
      padding: 30px;
      max-height: 80vh;
      max-width: 60vw;
      text-align: left;
      overflow-x: hidden;
      overflow-y: auto;
      border-radius: 10px;
      min-width: 25% ;
      .modalFormModelButton {
          width: fit-content;
          margin: 0 auto;
          padding-top: 10px;
          .button {
              margin: 5px;
          }
      }
  }
}
//custom quicklauncher

.contentComponent{ //ok
    // margin-left: 100px;
    // margin-right: 30px;
    padding-right: 0;
    padding-left: 0;
    margin-top: 0;
    width: 100%;
    transition: margin-left 0.2s ease-in-out, margin-right 0.2s ease-in-out;
    overflow: hidden;
    // @include xs {
    //     overflow-x: hidden;
    //    margin-left: 0px;
    //    margin-right: 50px;
    // }
    // @include md {
    //     overflow-x: hidden;
    //    margin-left: 0px !important;
    //    margin-right: 50px !important;
    // }

}
// page containe element.style {
//     overflow: auto;
//     position: absolute;
//     top: 0;
//     right: 0;
//     left: 0;
//     bottom: 0;
// }
// STYLES MENU

.contentHeader{ // ok
  position: fixed;
  width: 100%;
  z-index:1000;
  top: 0;
  left: 0;
  padding-right: 45px;
  background: transparent;
}

/* .mainScreenContextual {
    display: flex;
    align-content: space-between;
} */

.contentMenu {    // ok
    display: flex;    
    position: fixed;
    z-index: 1001; 
    height: 100%;
}


.menuLeft{ //ok
    //justify-content: start;
    display: flex;
    align-content: flex-start;
    height: 100%;
    
}

.contentContextual{ //o
    // margin-top: 55px;
    height: 100%;
    background-color:#D3D3D3;
    position: fixed;
    top: 0;
    color:#252526 ;
    z-index: 1111;
   // height: calc(100vh - 55px);
    // -webkit-box-shadow: -1px 0px 49px 2px rgba(0,0,0,0.4);
    // -moz-box-shadow: -1px 0px 49px 2px rgba(0,0,0,0.4);
    // box-shadow: -1px 0px 49px 2px rgba(0,0,0,0.4);
    transition: right 0.2s ease-in-out;
    @include xs {
        width: 250px;
    }
    @include md {
        width: 320px;
    }
  }
  
  .contentContextualBis{
    width: 40px;
    right: 0;
    position: fixed;
    top: 55px;
    z-index: 0;
    height: 100vh;
    -webkit-box-shadow: -1px 0px 49px 2px rgba(0,0,0,0.4);
    -moz-box-shadow: -1px 0px 49px 2px rgba(0,0,0,0.4);
    box-shadow: -1px 0px 49px 2px rgba(0,0,0,0.4);
    transition: right 0.3s ease-in-out;
  }

  .hideInput {
    opacity:0;
    height: 0;
    position: absolute;
    left: 0;
    padding: 0;
    bottom: 0;
    z-index: -1 !important;
}

/* .menuContainer{
    width : 100%;
    height: 100%;
} */

/* .contentMenuContainer{
    background: rgba(0,0,0,0.5);
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
} */

.arrowHover:hover span {
    transform: scale(2);
    background: green;
}

$tailleSpinner: 24px;

:global(.spinnerButton){
    height: $tailleSpinner !important;
    width: $tailleSpinner !important;
    position: absolute;
    left: calc(50% - #{$tailleSpinner}/2);
    color: white !important;
    top: calc(50% - #{$tailleSpinner}/2);
}

/* html {
    cursor: url(https://i.ibb.co/6r1MH3P/unicorn-png-curseur.png), auto;
} */

.addrole{
    cursor : pointer;
    margin-bottom: 20px;
    span{
        font-size: 20px;
    }
}

.btnAddUser{
    margin-left: 30% !important;
    margin-bottom : 24px !important;
}

.styleBtnRadio{
    display : flex;
}
.tableElement {
    display: inline-block !important;
    width: 100% !important;
    margin-top: -4% !important;
    border-radius: 6px !important;
    margin-bottom: 2% !important;
    padding-top: 5% !important;
    padding-bottom: 3% !important;
    padding-left: 2% !important;
    padding-right: 2% !important;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
    color:rgba(0,0,0,0.87);
}

.contentCheckbox{
    color: white;
    padding: 2%;
    border-radius: 3px;
    margin-right: 1vw;
    margin-left: 1vw;
}

/* Ecran chargement au début pour l'authentification */
.authLoadingContainer{
    display: flex;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #f3f3f3;

    .loadingLogo {
        width: 10vw;
        min-width: 90px;
        height: auto;
        @include slideArrow;
    }
}

/************* Style global de tous les tableaux bootstrap ****************************/
/* Default style BootstrapTable */
:global(.react-bs-table-container){

    /* Search Bar */
    :global(.react-bs-table-tool-bar ){
        margin-top: 19px !important;
    }

    /* Table */
    :global(.react-bs-table){

        /* Header */
        :global(.react-bs-container-header){

            /* Table */
            table{

                thead{
                    background: #A4A5AE;
                    color: #222;
                    tr{

                        th{
                            text-align: left;
                            border: none;
                            padding: 5px 0.75rem;
                        }
                    }
                }
            }
        }

        /* Content */
        :global(.react-bs-container-body){
            table{

                tbody{

                    tr{

                        td{
                            text-align: left;
                            margin-top: 4px;
                            padding:0px 0.75rem;
                        }
                    }
                }
            }
            :global(.react-bs-table-no-data){
                text-align: center;
            }
        }
    }
}

/************* Style global des entetes et des pages ****************************/
:global(.mainContent) {
    color: rgba(0, 0, 0, 0.87);
    // width: 75%;
    border: 0;
    display: flex;
    position: relative;
    font-size: .875rem;
    min-width: 0;
    background: #FFF;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
    margin-top: 50px;
    border-radius: 6px;
    flex-direction: column;
    margin-bottom: 2%;

    :global(.titleComponent) {
        color: #FFF !important;
        padding: 15px;
        margin-top: -20px;
        margin-bottom: 15px;
        border-radius: 3px;
        margin-right: 1vw;
        margin-left: 1vw;
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .14), 0 7px 10px -5px rgba(19, 100, 87, 0.4);

        h5 {
            margin: 0;
        }
    }

    :global(.contentComponent) {
        flex: 1 1 auto;
        padding: 0 20px 0.9375rem 20px;
        position: relative;
        -webkit-box-flex: 1;
        width: 100%;
    }
}