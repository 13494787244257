@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} {
    @content;
  }

  @-moz-keyframes #{$animationName} {
    @content;
  }

  @-o-keyframes #{$animationName} {
    @content;
  }

  @keyframes #{$animationName} {
    @content;
  }
}

@mixin animate($name, $duration, $timing, $iteration, $delay, $direction) {
  -webkit-animation-duration: $duration;
  -moz-animation-duration: $duration;
  -o-animation-duration: $duration;
  animation-duration: $duration;
  -webkit-animation-timing-function: $timing;
  -moz-animation-timing-function: $timing;
  -o-animation-timing-function: $timing;
  animation-timing-function: $timing;
  -webkit-animation-iteration-count: $iteration;
  -moz-animation-iteration-count: $iteration;
  -o-animation-iteration-count: $iteration;
  animation-iteration-count: $iteration;
  -webkit-animation-name: $name;
  -moz-animation-name: $name;
  -o-animation-name: $name;
  animation-name: $name;
  -webkit-animation-direction: $direction;
  -moz-animation-direction: $direction;
  -o-animation-direction: $direction;
  animation-direction: $direction;
  -webkit-animation-delay: $delay;
  -moz-animation-delay: $delay;
  -o-animation-delay: $delay;
  animation-delay: $delay;
}

/* Animation gauche position initiale */
@include keyframes(slideArrow) {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-5px);
  }
}

/* Animation gauche position initiale plus rapide*/
@include keyframes(slideArrowFast) {
  0% {
    transform: translateX(0);
  }

  5% {
    transform: translateX(-5px);
  }

  10% {
    transform: translateX(-0px);
  }

  100% {
    transform: translateX(0);
  }
}

/* Mixin animation gauche position initiale */
@mixin slideArrow {
  @include animate(slideArrow, 1.5s, linear, infinite, 0, alternate);
}

/* Mixin animation gauche position initiale plus rapide */
@mixin slideArrowFast {
  @include animate(slideArrowFast, 5s, linear, infinite, 2s, normal);
}

.iconArrow {
  @include slideArrow;
}

.iconArrowFast {
  @include slideArrowFast;
}


/* Animation chargement Authentication */
@include keyframes(loadingEffect) {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

/* Mixin animation chargement Authentication */
@mixin slideArrow {
  @include animate(loadingEffect, 1s, ease-in-out, infinite, 0, alternate);
}