.errorPageContainer {
    display: flex;
    align-items: center;
    height: 100vh;
    flex-direction: column;
    padding: 20px;

    .imgContainer {

        img {
            width: 100%;
            height: auto;
            max-width: 390px;
        }
    }

    .textContainer {
        text-align: center;
        padding-top: 20px;
    }
}

@media (min-width: 700px) {
    .errorPageContainer {
        flex-direction: row;
    
        .textContainer {
            text-align: left;
            padding-left: 20px;
        }
    }
}