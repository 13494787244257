$screen-md-max: 768px;
$screen-sm-min: 576px;

@mixin xs {
   @media (max-width: #{$screen-md-max}) {
       @content;
   }
}

.testFunc{
    background-color: transparent !important;
    margin-left:-600px;
}
.testFuncTwo{
    margin-top: -9px;
    height: 100vh;
    background-color: #252526 !important;
    margin-left: -40px;
    min-width: max-content;
}
:global(span[class*='sidenav---icon-bar---']){
    @include xs {
    background-color : #252526 !important;
    }
}

.sidenav  {
    background-color :#252526 !important;
    @include xs {
        background : transparent !important;
        button {
        height: 38px;
        width: 38px;
    }
    svg, i, img {
            // display: none;
            visibility: hidden;
        }
    }
    a, span, i {
        color: #D3D3D3 !important;
    }
    background: {
        opacity: 0.33;
    };
    
    .navIcon {
        :global(div[class*='sidenav---navicon---']){
            display: flex !important;
            align-items: center;
            justify-content: center;
            color: #D3D3D3 !important;
        }
        :global(div[class*='sidenav---navtext']){
            color: #D3D3D3 !important;
        }

        .iconFluent {
            font-size:20px;
        }
    }
    :global(div[class*='sidenav---sidenav-nav']){
        :global(div[class*='sidenav---navitem']):hover{
            background-color: inherit;
        }
    }
    :global(div[class*='sidenav---sidenav-subnav---']){
        background-color: #252526;
        color: #D3D3D3 ;
        :global(div[class*='sidenav---navtext']):hover{
            // font-size: 1.1em;
            color: #FFFFFF ;
        }
        :global(div[class*='sidenav---sidenav-subnavitem---']):hover{
            background-color: rgba($color: #555, $alpha: 0.9) ;
        }
        :global(div[class*='sidenav---sidenav-subnavitem---']):nth-child(1):hover{ // A l'arrache
            background-color: transparent !important;
            cursor: default;
        }
    }
    .menuCount {
        // :global(div[class*='sidenav---navtext']):hover{
        //     background-color: black !important;
        // }
        :global(div[class*='sidenav---navicon---']){
            display: flex !important;
            align-items: center;
            justify-content: center;
            color: #D3D3D3 !important;
        }
        :global(div[class*='sidenav---sidenav-subnav---']){
            display: block !important;
        }
        :global(div[class*='sidenav---navtext']){
            color: #D3D3D3 !important;
        }
    }
    .navCollapsed {
        :global(div[class*='sidenav---navicon---']){
            display: flex !important;
            align-items: center;
            justify-content: center;
            color: #D3D3D3 !important;
        }

        :global(div[class*='sidenav---sidenav-subnav---']){
            display: none !important;
        }
        :global(div[class*='sidenav---navtext']){
            color: #D3D3D3 !important;
        }
    }
    path {
        color: #D3D3D3;
    }

    .navIconsContainer {
        // overflow-y: auto;
        // height: calc(100% - 64px);
        // overflow-x: hidden;
    }

    .navIconsContainerMobile {
        // overflow-y: auto;
        // height: calc(100% - 30px);
        // overflow-x: hidden;
    }
}

:global(div[class*='sidenav---sidenav-subnavitem---'])::before{
    background-color:transparent !important;
}

:global(div[class*='sidenav---sidenav-subnavitem---']){
    position: relative;
    padding-left: 23px;
}


:global(div[class*='sidenav---selected---']){
    background-color:  #10a544 !important;
    @include xs {
       width: 0;
    }
    &:hover{
        background-color:  #10a544 !important;
    }
}
:global(div[class*='MuiToolbar-root MuiToolbar-regular MuiToolbar-gutters']){
    padding-left: 0 !important;
}

:global(div[class*='sidenav---sidenav-subnav---'] > div[class*='sidenav---selected---'] > div[class*='sidenav---navitem---']):hover {
    background:  #10a544 !important;
}
// :global(div[class*='sidenav---sidenav---_2tBP.sidenav---collapsed---1KdUL .sidenav---sidenav-subnav---1EN61']):hover {
//     display: none !important;
// }

.menuName{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    max-width: 175px;
}
.versionApp {
  position: absolute;
  bottom: 0;
  color: #D3D3D3 !important;
  font-size: 11px;
  width: 100%;
  text-align: center;
   
}