.loadingLogoComponent {
    width: auto;
    height: auto;
    margin: 0 auto;
    text-align: center;

    .loadingLogoContainer {
        width: 10vw;
        min-width: 90px;
        max-width: 110px;
        height: 10vw;
        min-height: 90px;
        max-height: 110px;
        margin: 35px auto;
        position: relative;
        transform: rotateZ(45deg);
        -o-transform: rotateZ(45deg);
        -ms-transform: rotateZ(45deg);
        -webkit-transform: rotateZ(45deg);
        -moz-transform: rotateZ(45deg);
    
        .cssload-cube {
            position: relative;
            transform: rotateZ(45deg);
            -o-transform: rotateZ(45deg);
            -ms-transform: rotateZ(45deg);
            -webkit-transform: rotateZ(45deg);
            -moz-transform: rotateZ(45deg);
        }
    
        .cssload-cube {
            float: left;
            width: 50%;
            height: 50%;
            position: relative;
            transform: scale(1.1);
            -o-transform: scale(1.1);
            -ms-transform: scale(1.1);
            -webkit-transform: scale(1.1);
            -moz-transform: scale(1.1);
        }
    
        .cssload-cube:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgb(43, 160, 199);
            animation: cssload-fold-thecube 2.76s infinite linear both;
            -o-animation: cssload-fold-thecube 2.76s infinite linear both;
            -ms-animation: cssload-fold-thecube 2.76s infinite linear both;
            -webkit-animation: cssload-fold-thecube 2.76s infinite linear both;
            -moz-animation: cssload-fold-thecube 2.76s infinite linear both;
            transform-origin: 100% 100%;
            -o-transform-origin: 100% 100%;
            -ms-transform-origin: 100% 100%;
            -webkit-transform-origin: 100% 100%;
            -moz-transform-origin: 100% 100%;
        }
    
        .cssload-c2 {
            transform: scale(1.1) rotateZ(90deg);
            -o-transform: scale(1.1) rotateZ(90deg);
            -ms-transform: scale(1.1) rotateZ(90deg);
            -webkit-transform: scale(1.1) rotateZ(90deg);
            -moz-transform: scale(1.1) rotateZ(90deg);
        }
    
        .cssload-c3 {
            transform: scale(1.1) rotateZ(180deg);
            -o-transform: scale(1.1) rotateZ(180deg);
            -ms-transform: scale(1.1) rotateZ(180deg);
            -webkit-transform: scale(1.1) rotateZ(180deg);
            -moz-transform: scale(1.1) rotateZ(180deg);
        }
    
        .cssload-c4 {
            transform: scale(1.1) rotateZ(270deg);
            -o-transform: scale(1.1) rotateZ(270deg);
            -ms-transform: scale(1.1) rotateZ(270deg);
            -webkit-transform: scale(1.1) rotateZ(270deg);
            -moz-transform: scale(1.1) rotateZ(270deg);
        }
    
        .cssload-c2:before {
            animation-delay: 0.35s;
            -o-animation-delay: 0.35s;
            -ms-animation-delay: 0.35s;
            -webkit-animation-delay: 0.35s;
            -moz-animation-delay: 0.35s;
        }
    
        .cssload-c3:before {
            animation-delay: 0.69s;
            -o-animation-delay: 0.69s;
            -ms-animation-delay: 0.69s;
            -webkit-animation-delay: 0.69s;
            -moz-animation-delay: 0.69s;
        }
    
        .cssload-c4:before {
            animation-delay: 1.04s;
            -o-animation-delay: 1.04s;
            -ms-animation-delay: 1.04s;
            -webkit-animation-delay: 1.04s;
            -moz-animation-delay: 1.04s;
        }
    }
}



@keyframes cssload-fold-thecube {

    0%,
    10% {
        transform: perspective(280px) rotateX(-180deg);
        opacity: 0;
    }

    25%,
    75% {
        transform: perspective(280px) rotateX(0deg);
        opacity: 1;
    }

    90%,
    100% {
        transform: perspective(280px) rotateY(180deg);
        opacity: 0;
    }
}

@-o-keyframes cssload-fold-thecube {

    0%,
    10% {
        -o-transform: perspective(280px) rotateX(-180deg);
        opacity: 0;
    }

    25%,
    75% {
        -o-transform: perspective(280px) rotateX(0deg);
        opacity: 1;
    }

    90%,
    100% {
        -o-transform: perspective(280px) rotateY(180deg);
        opacity: 0;
    }
}

@-ms-keyframes cssload-fold-thecube {

    0%,
    10% {
        -ms-transform: perspective(280px) rotateX(-180deg);
        opacity: 0;
    }

    25%,
    75% {
        -ms-transform: perspective(280px) rotateX(0deg);
        opacity: 1;
    }

    90%,
    100% {
        -ms-transform: perspective(280px) rotateY(180deg);
        opacity: 0;
    }
}

@-webkit-keyframes cssload-fold-thecube {

    0%,
    10% {
        -webkit-transform: perspective(280px) rotateX(-180deg);
        opacity: 0;
    }

    25%,
    75% {
        -webkit-transform: perspective(280px) rotateX(0deg);
        opacity: 1;
    }

    90%,
    100% {
        -webkit-transform: perspective(280px) rotateY(180deg);
        opacity: 0;
    }
}

@-moz-keyframes cssload-fold-thecube {

    0%,
    10% {
        -moz-transform: perspective(280px) rotateX(-180deg);
        opacity: 0;
    }

    25%,
    75% {
        -moz-transform: perspective(280px) rotateX(0deg);
        opacity: 1;
    }

    90%,
    100% {
        -moz-transform: perspective(280px) rotateY(180deg);
        opacity: 0;
    }
}