.contentCreateForm {
  .infoTextContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    margin-top: 20px;
  }

  .renderFormContent {
    width: 100%;
    max-width: 600px;
    margin: auto;
  }
  

  .paramFormContainer {
    background: #e5e5e5;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;

    .titleParamForm {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h4 {
        margin: 0;
      }

      .titleParamRightSide {
        display: flex;
        align-items: center;

        .statusForm {
          border: 1px solid black;
          padding: 1px 5px;

          &:first-letter {
            text-transform: uppercase;
          }
        }

        .iconParamForm {
          margin-left: 15px;

          i {
            font-size: 22px;
            cursor: pointer;
          }
        }
      }
    }

    .paramArea {
      height: auto;
      display: flex;
      flex-wrap: wrap;

      .paramAreaField {
        margin: 0 15px;
      }

      .iconFormFieldContainer {
        .iconFormField {
          display: flex;
          align-items: center;

          i {
            font-size: 22px;
            margin-right: 10px;
          }
        }
      }

      .paramCircuitContainer {
        .inputIconContainer {
          display: flex;
          align-items: center;

          .editCircuitModel {
            padding: 0 10px;
            cursor: pointer;
            font-size: 18px;
          }
        }
      }

      .gestionnaireFormContainer {
        :global(.gestionnaireForm__value-container) {
          padding: 0 8px;
        }
        :global(.gestionnaireForm__indicator) {
          padding: 0 8px;
        }
      }
      
    }

    .paramAreaClose {
      height: 0;
      overflow: hidden;
    }
  }

  .errorMessage {
    text-align: center;
    margin: 1rem 0;
  }
}

.formPanel {
  .panelButtonContainer {
    display: flex;
    align-items: center;
  }

  .choicesListContainer {
    .choicesList {
      .choicesItem {
        display: flex;

        .choicesItemInput {
          width: 100%;
        }

        .choicesItemIcon {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }
    }
  }
}