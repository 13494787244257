.messages{
    height: 45vh;
    width: 100%;
    max-width: 1000px;
    position: relative;
    border: 1px solid #38D6FD;
    border-radius: 6px;
    .errorMessage{
        text-align: center;
        margin: auto;
    }
    .missMessagesBox{
        // flex: 1;
        position: absolute;
        width: 100%;
        bottom: 50px;
        z-index: 100;
        .missMessages{
            cursor: pointer;
            background-color: #777D;
            border-radius: 10px;
            padding: 5px;
            font-weight: bold;
            color: white;
            width: fit-content;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .listMessages{
        width: 100%;
        height: max-content;
        max-height: 45vh;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        padding-bottom: 40px;
        .messageContainer{
            
            .message{
                margin: 10px;
                margin-top: 0;
                .myMessageBox{
                    margin-left: 30% !important;
                    margin-right: 0% !important;
                    background-color: rgb(56, 214, 253) !important;
                }
                .messageBox{
                    border-radius: 10px;
                    padding: 10px;
                    margin-right: 30%;
                    width: 70%;
                    background-color: rgb(228, 228, 228);
                    text-overflow: clip;
                    overflow: hidden;
                    .profilPicture{
                        cursor: pointer;
                        padding: 3px;
                        float: left;
                        margin-right: 10px;
                        border-radius: 50%;
                        color: rgb(255, 255, 255);
                        background-color: #A4A5AE;
                        width: 30px;
                        height: 30px;
                        font-weight: bold;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
            .dateContainer {
                width: max-content;
                height: 0;
                background-color: #d2cece;
                color: #FFF;
                border-radius: 10px 10px 0px 0px;
                opacity: 0;
                transition: all 0.3s ease-in-out;
                margin: auto;
            }  
            .dateContainerShow {
                padding: 10px;
                display: block;
                opacity: 1;
                height: fit-content;
                color: #000;
            }
            .textBox{
                text-align: left;
            }
        }
        
    }
    form{
        position: absolute;
        bottom: 0;
        width: 100%;  
        display: flex;
        justify-content: center;
        .textInput{
            width: 75%;
            margin: 10px;
            border-radius: 7px;
            border: 1px solid #777;
            padding-left: 5px;
        }
        .submitMessage{
            padding: 0px;
            border-radius: 10px;
            border: none;
            background-color: #FFF;
            .sendIcon{
                cursor: pointer;
                margin: 0;
                margin-top: 0px;
                width: 40px;
                margin-top: 3px;
                color: #38D6FD;
            }
        }
    }
}