.iconAction{
    cursor: pointer;
}

.listActionContainer{
    display: flex;
    justify-content: space-around;
}

.contentStep{
    display: flex;
    justify-content: space-evenly;
    background-color : #10a544;  
}

.displayStep{
    color : white;
}

.contentbodyModel{
    display : flex;
}

.contentModel{
    width: 60%;
    margin-left: 30px;
    margin-right: 30px;
    color : white;
}

.contentListNormal{
    width : 100%;
}
.contentListMini{
    width : 40%;
}

.nameStep{
    color : black;
    background-color : #10a544;
    // border: 2px solid #66CCCC;
    margin-top : 10px;
    padding-left: 5px;
    display: flex;
    .span{
        width: 250px;
    }
}
.firstContent {
    color: rgba(0, 0, 0, 0.87);
    width: 100%;
    border: 0;
    display: flex;
    position: relative;
    font-size: .875rem;
    min-width: 0;
    background: #FFF;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
    margin-top: 50px;
    border-radius: 6px;
    flex-direction: column;
    margin-bottom: 2%;
}
.roleTitle {
    color: #FFF !important;
    padding: 15px;
    margin-top: -20px;
    border-radius: 3px;
    margin-right: 1vw;
    margin-left: 1vw;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(19, 100, 87, 0.4);
}
.contentbodyRole{
    flex: 1 1 auto;
    padding: 0.9375rem 20px;
    position: relative;
    -webkit-box-flex: 1;
    margin-bottom: 2%;
    padding-bottom: 1%;
}
.role{
    border: 1px solid black;
}

.contentStepRole{
    width: 50px;
}
    
.icon{
    width: 100px;
    height: 100px;
    color: #10a544;
    font-size: 20px !important;
    cursor : pointer;
}

.iconArrow{
    width: 100px;
    height: 100px;
    color: #10a544;
    font-size: 26px !important;
    cursor : pointer;
}

.contentIcon{
    //margin-left: 97%;    
}

.iconModel{
    display: flex;
    justify-content: space-between;
}

.contentIconArrow{
    margin-left : 50%;
    font-size: 25px !important;
    cursor : pointer;
}

// .styleLine{
//     line-height : 0px !important;
// } 

// :global(.react-bs-table){
//     line-height: 0px !important;
// }

// :global(.react-bs-container-body){
//     line-height: 0px !important;
// }

// .iconAction :global(.material-icons){
//     line-height : 0px !important;
//     display: inline;
//     font-size: 16px;
// }