$screen-sm-max: 760px;
$screen-sm-min: 500px;


@mixin xs {
    @media (max-width: #{$screen-sm-max}) {
        @content;
    }
}

@mixin sm {
    @media (min-width: #{$screen-sm-min}) {
        @content;
    }
}

.contentFirst {
    color: rgba(0, 0, 0, 0.87);
    width: 100%;
    border: 0;
    display: flex;
    position: relative;
    font-size: .875rem;
    min-width: 0;
    background: #FFF;
    box-shadow: 0 1px 4px 0 rgba(54, 37, 37, 0.14);
    margin-top: 50px;
    border-radius: 6px;
    flex-direction: column;
    margin-bottom: 6%;

    .titleContent {
        color: #FFF !important;
        padding: 15px;
        margin-top: -20px;
        border-radius: 3px;
        margin-right: 1vw;
        padding-bottom: 14px;
        margin-left: 1vw;
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .14), 0 7px 10px -5px rgba(19, 100, 87, 0.4);

        h5 {
            margin-right: 30px;
        }

        @include xs {
            display: block;
        }
    }

    .tableElement {
        flex: 1 1 auto;
        padding: 0.9375rem 20px;
        position: relative;
        -webkit-box-flex: 1;
        margin-bottom: 2%;
        padding-bottom: 1%;
        width: 100%;

        .MuiTabs {
            @include xs {
                display: grid !important;
            }
        }

        .checkbox {
            margin-bottom: 20px;
            text-align: center;
        }

        .checkDiv {
            font-size: 14px !important;
            padding-bottom: 10px;
            @include xs {
                display: grid;
            }

            .paddingCheckbox {
                @include xs {
                    margin-bottom: -30px !important;
                }
            }

            .statutText {
                font-size: 16px !important;
            }
        }
    }
}

.contentMyTasks {

    /* Force table to not be like tables anymore */
    table,
    thead,
    tbody,
    td,
    th,
    tr {
        @include xs {

            table,
            thead,
            tbody,
            th,
            td,
            tr {
                display: block;
                text-align: left !important;
            }

            thead tr {
                position: absolute;
                top: -9999px;
                left: -9999px;

            }

            tr {
                border: 1px solid #ccc;
            }

            td {
                border: none;
                border-bottom: 1px solid #eee;
                position: relative;
                padding-left: 35%;
                padding-top: 7px;
            }

            td:before {
                /* Now like a table header */
                position: absolute;
                /* Top/left values mimic padding */
                top: 6px;
                left: 6px;
                width: 45%;
                padding-right: 10px;
            }

            th {
                max-width: 10px !important;
            }

            td:nth-of-type(1):before {
                content: "";
            }

            td:nth-of-type(2):before {
                content: "Nom du document";
            }

            td:nth-of-type(3):before {
                content: "Nom de la tâ""che";
            }

            td:nth-of-type(4):before {
                content: "Auteur";
            }

            td:nth-of-type(5):before {
                content: "Date";
            }

            td:nth-of-type(6):before {
                content: "Message";
            }
        }
    }
}

:global(div[class*='MuiTabs-flexContainer']) {
    margin-bottom: 5px;
    display: flex;

    @include xs {
        display: grid !important;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

    @include sm {
        display: flex !important;
        margin-left: inherit;
    }

    :global(div[class*='MuiTabs-root']) {
        @include xs {
            padding-right: 7vw;
        }
    }
}