$ms-color-white:white;
$ms-color-neutralLighter: grey;
$ms-color-neutralLight: green;
$ms-color-themeLighter: blue;
$ms-color-themeLight: yellow;
$ms-color-themeDark: black;

.draggableComponent {
    opacity: 1;
    background-color: transparent;
    position: relative;
}

.draggableComponent:hover {
    // background-color: $ms-color-neutralLighter;
    outline: 1px solid;
    outline-color: $ms-color-neutralLight;
    opacity: 0.7;
}

.toolbar {
    position: absolute;
    right: 0px;
    top: 0px;
    height: 100%;
    min-width: 34px;
    background-color: white;
    color: black;
    display: none;
    // overflow: hidden;
    text-align: center;
    transition: all .3s;
    transition-property: background-color, color;
    z-index: 20;

    .button {
        height: 34px;
        min-width: 34px;
        display: block;
        border: 1px solid transparent;
        color: black;
        background-color: white;
        padding: 0 8px;
        overflow: hidden;
        text-align: center;
        transition: all .3s;
        transition-property: background-color, color;
        font-weight: 400;
        font-size: 14px;

        &:hover {
            background-color: black;
            color: white;
        }
    }


}

.draggableComponent:hover .toolbar {
    display: block;
}