.orientationContainer {
  width: 100%;

  .buttonContainer {
    width: 100%;
    text-align: center;

    .customButton {
      background-color: blue;
      margin: 5px 0 5px 0;
      text-transform: none !important;
    }
  }
}


.orientationModal {
  .formControlModelSelect {
    min-width: 200px !important;
  
    .selected {
      background-color: rgb(202, 255, 202);
    }

    .gridContainer {
      .cardsContainer {
        .card {
          height: 110px;
          display: flex;
          flex-direction: column;
          cursor: pointer;
          // &:hover {
          //   background-color: #e9ecef;
          // }
  
          .iconCards {
            display: flex;
            width: 100%;
            height: 100%;
            align-items: center;
            justify-content: center;
          }
  
          .modelOrientation {
            text-align: center;
            text-overflow: ellipsis !important;
            overflow: hidden !important;
            white-space: nowrap !important;
            border-bottom: 1px solid #c0c2c4;
            padding: 8px 10px;
          }
        }
      }
    }

  }

  .customButtonDialogValid {
    margin: 5px 0 5px 0;
    text-transform: none !important;
  }
  
  .customButtonDialogCancel {
    margin: 5px 0 5px 0;
    text-transform: none !important;
  }
}