.renderFormContainer {
    .titleFormInput {
        margin-bottom: 10px;

        :global(.ms-TextField-fieldGroup) {
            height: auto !important;

            input {
                font-size: 30px;
            }
        }
    }

    .addFieldToolbox {
        width: 100%;
        display: flex;
        justify-content: center;
        cursor: pointer;
        margin-top: 15px;

        .addFieldToolboxPlusButton {
            background: grey;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            i {
                color: white;
            }
        }
    }
}