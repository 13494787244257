$formBackground: #ffffff;
.formStyle {
  background: $formBackground;
  :global(.MuiOutlinedInput-input){
    padding : 8px 14px !important;
  }
  .labelFix {
    padding: 0 5px !important;
    background: $formBackground !important;
    display: flex;
  }

  .titleField{
    padding-bottom: 10px;
    padding-top: 10px;
    font-weight: bold;
  }

  .groupInput{
    margin-bottom: 10px;
    margin-top: 10px;
    width: 100%;
  }

  .contentBouttonSharepoint button:first-child{
    margin-right: 15px;
  }
}