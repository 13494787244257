.taxoInputButtonContainer {
    display: flex;

    .taxoInput {
        width: 100%;
    }

    .iconContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 10px;

        .icon {
            cursor: pointer;
            font-size: 25px;
        }
    }

    :global(.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]) {
        padding-top: 2px !important;
        padding-bottom: 2px !important;
    }
}

.taxonomyModal {
    min-width: 50vw;
    max-width: 80vw !important;

    .modalTitle h2 {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .modalContent {
        .termContainer {
            display: flex;
            align-items: center;

            .iconTree {
                font-size: 25px;
                margin-right: 10px;
                cursor: pointer;
            }

            .formControlLabel {
                margin-bottom: 0;
            }
        }
    }

}