@import url('https://fonts.googleapis.com/css?family=Dekko&display=swap');
$screen-sm-max: 780px;
$screen-sm-min: 576px;

@mixin xs {
    @media (max-width: #{$screen-sm-max}) {
        @content;
    }
}

.noWorkflowMessage {
    padding: 10px;
}

.btnPostit {
    margin-left: 5px;
    cursor: pointer;
}

.postItContainer {
    position: relative;
    //  height: 50vh;
}

.iconPostit {
    margin-left: auto;
}

.postIt {
    overflow: hidden;
    width: 80%;
    max-width: 400px;
    margin: 15px;
    box-sizing: border-box;
    position: relative;

    @include xs {
        width: 80%;
    }

    .iconContainer {
        position: relative;
        display: flex;
        justify-content: flex-end;
        cursor: pointer;
        align-items: center;
        padding: 2px;
        border-bottom: 1px solid #18181826;

        .iconAction {
            cursor: pointer;
            margin: 0 2px;
        }
    }

    .iconContainerBleu {
        justify-content: space-between;
    }

    .messagesContainer {
        height: 220px;
        width: 100%;
        padding: 10px;

        .messageContainer {
            display: flex;

            .messageContent {
                background: #fdfdfd8a;
                padding: 5px;
                margin-bottom: 5px;
                border-radius: 10px;

                &:hover + .iconsActionMessageContainer {
                    display: flex;
                }

                .titreMessage {
                    font-weight: bold;
                    margin: 0;
                    font-size: 0.60rem;
                }
        
                .messageStyle {
                    margin-bottom: 0;
                    padding: 0 10px;
                    font-family: 'Dekko', cursive;
                    font-size: 1rem;
                }

                .imgStyle {
                    margin-bottom: 0;
                    padding: 0 10px;
                    width:170px;
                    height: auto;
                }
            }

            .iconsActionMessageContainer {
                display: none;
                align-items: center;

                .iconActionMessage {
                    font-size: 20px;
                    cursor: pointer;
                }

                &:hover {
                    display: flex;
                }
            }
        }

        // @include xs {
        //     height: 150px;
        // }

        .noMessageContainer {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
        }
    }

    .editContainer {
        padding: 5px;
        .buttonContainer {
            display: flex;
            justify-content: flex-end;

            .iconSend {
                color: grey;
            }

            .iconActive {
                cursor: pointer;
                color: black;
            }
        }
    }
}

.resetPostIt {
    display: flex;
}

.postItComponent {
    display: flex;
    height: 100%;
    flex-direction: column;
}

:global(#PostItJaune) {
    background: #ffef63;
}

:global(div[id*="PostItBleu"]) {
    background: #599bff;
}