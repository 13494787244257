// @import '../../Switch/styles/treeSelect';
$screen-sm-max: 780px;


 @mixin xs {
    @media (max-width: #{$screen-sm-max}) {
        @content;
    }
 }

table {
    @include xs {
    table-layout: inherit !important;
    }
}
/* Force table to not be like tables anymore */
.groupContent {
    margin-right: 1vw;
    margin-left: 1vw;
table, thead, tbody, td, th, tr { 
    @include xs {
        table, thead, tbody, th, td, tr { 
            display: block; 
        }
		
		/* Hide table headers (but not display: none;, for accessibility) */
		thead tr { 
			position: absolute;
			top: -9999px;
			left: -9999px;
		}
        text-align: left !important;

		tr { border: 1px solid #ccc; }
		
		td { 
			/* Behave  like a "row" */
			border: none;
			border-bottom: 1px solid #eee; 
			position: relative;
			padding-left: 50% !important; 
		}
		
		td:before { 
			/* Now like a table header */
			position: absolute;
			/* Top/left values mimic padding */
			top: 6px;
			left: 6px;
			width: 45%; 
			padding-right: 10px; 
        }
        
        td:nth-of-type(1):before { content: ""; }
		td:nth-of-type(2):before { content: "Nom"; }
		td:nth-of-type(3):before { content: "Prénom"; }
		td:nth-of-type(4):before { content: "Login"; }
        td:nth-of-type(5):before { content: "Actions"; }
        }
    }
}
.btnUser{
    width: 100px;
    margin-left: 10px !important;
}

.contentCreateUser{
    width: 100%;
    background-color: #ececec;
    .field{
        padding: 0 5px !important;
        width: 100%;
    }
    .fieldTitle {
        padding: 0 5px !important;
        width: 100%;
        margin: 15px 0;
        .ant-select-selection--single {
            height: auto;
            padding: 10px 0;
        }
    }

}

.textfieldCreateUser{
    margin-bottom : 25px;
    min-height: 100vh
}

.buttonColor{
    background-color: #10a544 !important;
    color:white !important;
    margin-bottom: 10px !important;
}
.fieldTree{
    width: 100%;
    padding: 0 5px !important;
    margin-top: 24px;
  }

.iconEdit {
    cursor: pointer;
}
.listActionContainer{
  display: flex;
  justify-content: space-around;
}
.contentListUsers {
    color: rgba(0, 0, 0, 0.87);
    width: 100%;
    border: 0;
    display: flex;
    position: relative;
    font-size: .875rem;
    min-width: 0;
    background: #FFF;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
    margin-top: 50px;
    border-radius: 6px;
    flex-direction: column;
    margin-bottom: 6%
}

.usersTitle{ 
    color: #FFF !important;
    padding: 15px;
    margin-top: -20px;
    border-radius: 3px;
    margin-right: 1vw;
    margin-left: 1vw;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(19, 100, 87, 0.4);
}

.usersContent {
    flex: 1 1 auto;
    padding: 0.9375rem 20px;
    position: relative;
    -webkit-box-flex: 1;
    margin-bottom: 2%;
    padding-bottom: 1%;
}

.buttonUserContent {
    display: inline-block;
    width: 100%;
    margin-top: -4%;
    padding-top: 5%;
    padding-left: 2%;
    padding-right: 2%;
}
.mainContent{
    color: rgba(0, 0, 0, 0.87);
    width: 100%;
    border: 0;
    display: flex;
    position: relative;
    font-size: .875rem;
    min-width: 0;
    background: #FFF;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
    margin-top: 50px;
    border-radius: 6px;
    flex-direction: column;
    margin-bottom: 2%;
    .checkbox {
        margin-left: 5px;
    }
    .button {
        margin-left: 5px;
        margin-bottom: 5px;
    }
}