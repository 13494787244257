.snackBarContent {
    .success {
        background-color: green !important;
    }

    .error {
        background-color: red !important;
    }

    .warning {
        background-color: yellow !important;
    }

    .icon {
        font-Size: 20;
        cursor: pointer;
    }

    .iconVariant {
        opacity: 0.9;
        margin-right: 5px;
        font-Size: 20;
    }

    .message {
        display: flex;
        align-items: center;
    }
}