$backgroundColorContextual : #ececec;

//Menu contextuel
.contextualZone{
    min-height: 1000px;
    width: 335px;
    box-shadow: -30px 0px 30px -30px rgba(0,0,0,0.4);
    background-color: $backgroundColorContextual;
}

.contentBtn{
    margin-left: 1%;
}
.paramTitle{
    color: #FFF !important;
    padding: 15px;
    margin-top: -20px;
    border-radius: 3px;
    margin-right: 1vw;
    margin-left: 1vw;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(19, 100, 87, 0.4);
}
.firstContent {
    color: rgba(0, 0, 0, 0.87);
    width: 100%;
    border: 0;
    display: flex;
    position: relative;
    font-size: .875rem;
    min-width: 0;
    background: #FFF;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
    margin-top: 50px;
    border-radius: 6px;
    flex-direction: column;
    margin-bottom: 2%;
}
.paramContent{
    flex: 1 1 auto;
    padding: 0.9375rem 20px;
    position: relative;
    -webkit-box-flex: 1;
    margin-bottom: 2%;
    padding-bottom: 1%;
}

// .ComponentTest{
//     width: 100%;
// }