.signContainer {
  width:100%;
  .divContainer{
    width:100%;
    text-align:center;
    .customButton{
      background-color:blue;
      margin: 5px 0 5px 0;
      text-transform: none !important;
    }
  }
  
  .iconCards {
    cursor: pointer;
    padding: 0;
    width: 100%;
    height: 70%;
    text-align: center;

    svg {
      width: 100%;
      height: 100%;
    }
  }
  height: 104px;
}
.formControlModelSelect {
  min-width: 200px !important;
  :global(.selected) {
    background-color: rgb(202, 255, 202);
  }
}
.customButtonDialogValid{
  margin: 5px 0 5px 0;
  text-transform: none !important;
}
.customButtonDialogCancel{
  margin: 5px 0 5px 0;
  text-transform: none !important;
}
