$green: #43a047;
$green-light:#65C369;
$red:#e53935;
$red-light:#f75f5c;
$blue:#00acc1;
$blue-light:#03cbe6;
$yellow:#fb8c00;
$yellow-light:#fda841;
$grey: #666666;
$grey-light: #888888;



@mixin actionButton {
    box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.12);
    transition: background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    will-change: box-shadow, transform;
}

.actionTable{
    display: flex;
    line-height: 1;
    div{
        width: max-content;
        padding: 3px;
        border-radius: 4px;
        margin: 5px;
        cursor: pointer;
        .iconAction{
            color: #FFF;
            font-size: 19px;
        }
    }
    .actionVert{
        @include actionButton();
        background-color: $green;
        &:hover{
            background-color: $green-light;
        }
    }
    .actionBleu{
        @include actionButton();
        background-color: $blue;
        &:hover{
            background-color: $blue-light;
        }
    }
    .actionJaune{
        @include actionButton();
        background-color: $yellow;
        &:hover{
            background-color: $yellow-light;
        }
    }
    .actionRouge{
        @include actionButton();
        background-color: $red;
        &:hover{
            background-color: $red-light;
        }
    }
}

.block{
    color: rgba(0, 0, 0, 0.87);
    width: 100%;
    display: flex;
    border: 0;
    font-size: .875rem;
    min-width: 0;
    background: #FFF;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
    margin-top: 50px;
    border-radius: 6px;
    flex-direction: column;
    margin-bottom: 3%;
    padding-left: 20px;
    padding-right: 20px;
    .contentCheckbox{
        margin-top: -25px !important;
        margin-bottom: 20px;
        display: flex;
        color: #FFF !important;
        padding: 15px;
        border-radius: 3px;
        margin-right: 1vw;
        padding-bottom: 6px;
        margin-left: 1vw;
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(19, 100, 87, 0.4);
        h5 {
            margin-right: 40px;
        }
        label {
            margin-top: -10px;
            margin-right: 10px;
        }
    }
    .menu{
        margin-bottom: 20px;
        .menuItem{
            padding: 5px 20px 5px 20px;
        }
    }
    // .iconAction{
    //     color: #FFF;
    //     font-size: 19px;
    // }
    .nbTask{
        vertical-align: text-bottom;
        color: #FFF;
        font-size: 15px;
        font-weight: bold;
    }
    .tableDeleg{
      margin-bottom: 10px;
    }
    .trClassNameTable{
        animation-duration: 1.5s;
        animation-name: clignoter;
        animation-iteration-count: 2;
        transition: none;
    }
    .tableHeader{
        background: #A4A5AE;
        color: #222;
    }
    .tableThHeader{
        th{
            padding-top: 15px;
            padding-bottom: 15px;
        }
    }
    @keyframes clignoter {
        0% {background-color: #ffe880;}
        20% {background-color: #fce890;}
        40% {background-color: #faeaa5;}
        60% {background-color: #fdf2c2;}
        80% {background-color: #faf4db;}
        100% {background-color: #ffffff;}
    }
    .tdElement{
        margin-top: 4px;
    }
    .actionBar{
        z-index: 999;
        display: flex;
        .activeItem{
            background-color: #BBB;
            margin-left: 15px;     
            height: 35px;
            .actionIcon{
                margin-right: 10px;
            }
        }
        .actionItem{
            margin-left: 15px;
            height: 35px;
            .actionIcon{
                margin-right: 10px;
            }
        }
    }
    
    .badge{
        :global(.MuiBadge-badge){
            top: 5px;
            right: 5px;
            border: 2px solid #FFF;
            width: 10px;
            height: 10px;
            background-color: #F00;
            border-radius: 50%;
        }
    }
    
    .ligne{
        margin: auto;
        margin-bottom: 20px;
        width: 80%;
        height: 0;
        border: 1px #10a544 solid;
    }
    .taskTitle{
        display: block;
        color: #444;
        font-size: 20px;
        margin-left: 40px;
        margin-bottom: 10px;
    }
}

.modalTitle{
    text-align: center;
}
.modalContent{
    text-align: center;
    .modalButtons{
        margin-top: 10px;
        margin-bottom: 15px;
        display: flex;
        justify-content: space-around;
    }
}