.fileExplorerContainer {
    .breadCrumbsContainer {
        padding: 10px;

        span:last-child {
            font-weight: bold;
        }
    }

    .fileExplorer {
        max-height: 300px;

        .listFileExplorer {
            .listItemFileExplorer {
                border-top: 0.2px solid #e2e2e2;
                padding-top: 2px;
                padding-bottom: 2px;

                .FEIcon {
                    padding: 5px 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 28px;
                }
            }
        }
    }
}