.SPFieldDateTimeContainer {
    display: flex;
    width: 100%;
    align-items: flex-end;

    .SPFieldDateTime {
        width: 100%;
    }

    .SPFieldDateTimeHour {
        margin-left: 10px;
    }
}